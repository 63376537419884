<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Insumos</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    
                        <v-btn  dark color="#023145" width="140" class="float-right"
                                @click="handleShowCreate">
                            Nuevo
                        </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pt-1">
                    <v-text-field
                        v-model="search_product"
                        label="Buscar"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        @keydown="handleInputSearchByEnter"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="5" class="pt-1">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
                <!--<v-col cols="3" class="pt-1">
                    <v-btn
                        v-if="this.$hasPermision('productos.filters')"
                        dark
                        color="#023145"
                        outlined
                        width="140"
                        class="float-right"
                        @click="handleShowFilters"
                    >
                        <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
                        Ver Filtros
                    </v-btn>
                    <v-btn
                        dark
                        color="green"
                        class="float-right mr-2"
                        @click="handleDownload"
                    >
                        <v-icon class="mr-1" size="16">fas fa-download</v-icon>
                        Descargar
                    </v-btn>
                </v-col>-->
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers"
                            :items="insumos"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            show-select
                            :single-select="true"
                            @item-selected="handleSelectItem"
                            :loading="loading"
                            :options.sync="options"
                            loading-text="Loading... Please wait"
                        >

                        <template v-slot:item.name="{ item }">
                            <span v-if="item.name">{{ item.name }}</span>

                            <span v-if="item.status == 'activo'" class="activo">
                                <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                activo
                            </span>
                            <span v-else class="inactivo">
                                <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                inactivo
                            </span>
                        </template>

                        <template v-slot:item.category_id="{ item }">
                            <span v-if="item.category">{{ item.category.name }}</span>

                        </template>

                        <template v-slot:item.unit_id="{ item }">
                            <span v-if="item.unit">{{ item.unit.name }}</span>
                        </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <div class="text-center">
            <v-bottom-sheet
                v-model="show_options_crud"
                :hide-overlay="true"
                persistent
                :retain-focus="false"
            >
                <v-sheet class="text-center" height="85px">
                    <div class="py-3">
                        <v-btn
                            v-if="this.$hasPermision('productos.edit')"
                            class="mt-3"
                            dark
                            color="blue"
                            outlined
                            @click="handleEdit"
                        >
                            <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
                            Editar
                        </v-btn>
                        <v-btn
                            v-if="
                                show_btn_active == 'inactivo' && this.$hasPermision('productos.change_status')
                            "
                            class="ml-4 mt-3"
                            dark
                            color="green"
                            outlined
                            @click="handleChangeStatus"
                        >
                            <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
                            Activar
                        </v-btn>
                        <v-btn
                            v-if="
                                show_btn_active == 'activo' &&
                                this.$hasPermision('productos.change_status')
                            "
                            class="ml-4 mt-3"
                            dark
                            color="amber"
                            outlined
                            @click="handleChangeStatus"
                        >
                            <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
                            Inactivar
                        </v-btn>
                        <!--<v-btn
                            v-if="this.$hasPermision('productos.destroy')"
                            class="ml-4 mt-3"
                            dark
                            color="red"
                            outlined
                            @click="handleDelete"
                        >
                            <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
                            Eliminar
                        </v-btn>-->
                    </div>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    
        <create-update ref="createUpdate" :devolver-data="handleAddAnalysis"></create-update>
    </v-container>
</template>-->

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router/index";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import CreateUpdate from '../../../components/insumos/CreateUpdate';
import SuppliesApi from '../../../apis/Supplies';
import ArrayTools from "../../../helpers/ArrayTools";

export default {
    name: "Insumos",
    components: {
        OfficeLogin,
        CreateUpdate
    },
    watch: {
        option: {
            handler() {
                this.loading = true;
                console.log(this.options);
                this.perPage = this.options.itemsPerPage;
                this.pageSelected = this.options.page;
                const search = this.search_product;
                console.log(search);
                if (search != null && search.trim().length > 0) {
                    SuppliesApi.get_all({
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                        name: search//this.search_product,
                    }).then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                } else {
                    SuppliesApi.get_all({
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                        //name: search//this.search_product,
                    }).then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                }
            },
            deep: true
        }
    },
    data() {
        return {
            insumos: [],
            selectedRows: [],
            itemsBreadcrumbs: [
                {
                text: "Insumos",
                disabled: true,
                href: "",
                },
            ],
            headers: [
                { text: "NOMBRE", value: "name", width: 500 },
                { text: "CATEGORIA", value: "category_id" },
                { text: "UND. DE MEDIDA", value: "unit_id" },
                { text: "COSTO", value: "cost", align: "end" },
            ],
            loading: false,
            dataTable: { page: 1 },
            options: {},
            pageSelected: 1,
            perPage: 10,
            name: ''
        }
    },
    methods: {
        ...mapActions('notifications', [
            'store_notification'
        ]),
        handleShowCreate() {
            this.$refs.createUpdate.showForm('Nuevo Insumo', 'create')
        },
        handleEdit(){
            let supply = this.selectedRows[0]
            this.selectedRows = []
            this.showOptionCrud()
            this.$refs.createUpdate.showForm('Modificar datos de insumo', 'update', supply)
        },
        showOptionCrud() {
            this.show_options_crud = this.selectedRows.length > 0;
            console.log(this.show_options_crud);
            if (this.show_options_crud) {
                //let item = item.offices && item.offices.length == 1 && item.offices[0].active
                let item = this.selectedRows[0];
                console.log(item);
                //if (item.offices && item.offices.length == 1) {
                this.show_btn_active = item.status;
                console.log(this.show_btn_active);
                //}
                //this.show_btn_active = !this.selectedRows[0].active
            }
        },
        handleSelectItem(selected) {
            if (selected.value) {
                this.selectedRows.push(selected.item);
            } else {
                let index_found = ArrayTools.getIndexById(
                this.selectedRows,
                selected.item.id
                );
                if (index_found > -1) {
                this.selectedRows.splice(index_found, 1);
                }
            }
            this.showOptionCrud();
        },
        handleInputSearchByEnter(KeyboardEvent) {
            if (KeyboardEvent.code == "Enter") {
                this.handleSearch();
            }
        },
        async handleSearch() {
            this.selectedRows = [];
            /*this.changeFilterPaginated({
                name: null,
                sku: null,
                barcode: null,
                category_id: null,
                brand_id: null,
                unit_id: null,
                office_id: null,
            });*/
            this.showOptionCrud();
            this.loading = true;
            let filters = {};
            this.dataTable.page = 1;
            if (this.search_product != undefined) {
                console.log('el search tiene algo');
                console.log(this.search_product);
                if (this.search_product.trim().length > 0) {
                    filters = {
                        name: this.search_product,
                        office_id: this.office_id,
                    };
                } else {
                        filters = {
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                    };
                }
            } else {
                console.log('el search no tiene algo');
                console.log(this.search_product);
                filters = {
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                    };
            }
            
            await SuppliesApi.get_all(filters)
                .then((result) => {
                    console.log('este es el resultado');
                    console.log(result);
                    this.loading = false; 
                    if (this.search_product.trim().length > 0){
                        this.insumos = result.data;
                    }else{
                        this.insumos = result.data.data;
                    }
                    
                })
                .catch((errr) => (this.loading = false));
        },
        async handleChangeStatus() {
            let product = this.selectedRows[0];
            this.selectedRows = [];
            this.showOptionCrud();
            await SuppliesApi.change_status(product.id).then(resp => {
                this.store_notification({
                    type: 'success',
                    message: 'La actualización se realizó de forma correcta.    '
                });
                SuppliesApi.get_all({
                    paginated: true,
                    page: this.pageSelected,
                    itemsPerPage: this.perPage,
                    name: this.search_product,
                }).then((value) => {
                    console.log(value.data);
                    console.log(value.data.data);
                    this.insumos = value.data.data;
                });
            }).catch(error => {
                this.store_notification({
                    type: 'error',
                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                });
            });
            
            //await this.change_status(product);
        },
        handleAddAnalysis(item) {
            /*this.dataExamenes.push({
                ...item,
                id: null,
                delete: false,
                rango_referencial_detail: this.filterEtiquetas(item.rango_referencial),
            });

            this.cant_analisis = this.countItems(this.dataExamenes);
            },*/
            SuppliesApi.get_all({
                paginated: true,
                page: this.pageSelected,
                itemsPerPage: this.perPage,
                name: this.search_product,
            }).then((value) => {
                console.log(value.data);
                console.log(value.data.data);
                this.insumos = value.data.data;
            });
        },
    },
    created() {
        SuppliesApi.get_all({
            paginated: true,
            page: this.pageSelected,
            itemsPerPage: this.perPage,
            name: this.search_product,
        }).then((value) => {
            console.log(value.data);
            console.log(value.data.data);
            this.insumos = value.data.data;
        });
    },
}
</script>

<style scoped>
.section-categories {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif !important;
}

.header-table-products {
  vertical-align: baseline !important;
  padding-top: 5px !important;
}

.v-data-table-header > tr > th {
  color: #263238 !important;
  vertical-align: baseline !important;
  padding-top: 5px !important;
}
</style>